import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import WhatsApp from "./whatsapp";

const NavBarTablet = () => {
  return (
    <div className="absolute z-20  px-8 mx-auto w-full max-w-primary">
      <div className="flex justify-between items-center py-4">
        <Link to="/">
          <StaticImage
            alt="katsana"
            src="../images/logo.png"
            objectFit="contain"
            className="w-48"
            objectPosition="left"
          />
        </Link>
        <div className="flex items-center">
          <a
            href="https://apps.katsana.com/get-quotation/"
            className="flex px-6 pt-2 pb-3 ml-10 font-semibold rounded-full bg-primary text-white"
          >
            Request Consultation
          </a>
          <WhatsApp />
        </div>
      </div>
      <div className="flex items-center text-white font-semibold h-12">
        <Link
          to="/fleet-management-malaysia/"
          className="mr-6"
          activeClassName="border border-white rounded-full px-5 py-2"
        >
          Fleet Management Solutions
        </Link>
        <Link
          to="/industrial-iot/"
          className="mr-6"
          activeClassName="border border-white rounded-full px-5 py-2"
        >
          Integrated Operations
        </Link>
        <Link
          to="/success-stories/"
          className="mr-6"
          activeClassName="border border-white rounded-full px-5 py-2"
        >
          Customers
        </Link>
        <a
          href="https://my.katsana.com"
          target="_blank"
          rel="noopener noreferrer"
          className="flex"
        >
          Login
        </a>
      </div>
    </div>
  );
};

export default NavBarTablet;
