import * as React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Cta from "../components/cta";
import * as Styles from "../styles/effect.module.css";

const PrivacyPolicy = () => {
  return (
    <Layout pageTitle="Home Page">
      {/* MetaTag */}
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>
          Privacy Policy - KATSANA® Integrated Fleet Management & Industrial
          Operation Solutions
        </title>
        <meta
          name="description"
          content="We are transforming fleet & enterprise operations for the digital age."
        />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://www.katsana.com/privacy-policy/" />

        {/* Open Graph */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.katsana.com/privacy-policy/"
        />
        <meta
          property="og:title"
          content="Privacy Policy - KATSANA® Integrated Fleet Management & Industrial Operation Solutions"
        />
        <meta
          property="og:description"
          content="We are transforming fleet & enterprise operations for the digital age."
        />
        <meta
          property="og:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
        <meta
          property="og:image:alt"
          content="KATSANA Fleet Management & Industrial IoT Sensors Expert"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="640" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@katsanagps" />
        <meta name="twitter:creator" content="@katsanagps" />

        {/* image must be 2:1 max 5mb */}
        <meta
          name="twitter:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
      </Helmet>
      {/* Hero Area */}
      <div className="relative p-8 pt-12 pb-24 overflow-hidden xl:px-0  md:pt-32 md:pb-48">
        <StaticImage
          alt="katsana"
          src="../../static/images/photos/aboutus-hero.jpg"
          objectFit="cover"
          className="!absolute inset-0"
        />

        <div className="relative z-10 max-w-6xl mx-auto ">
          <h3 className="pt-24 md:pt-12 text-lg md:text-2xl text-bluegray">
            Important stuff that you need to know
          </h3>
          <h1 className="pt-2 text-3xl font-bold tracking-wide text-white md:leading-tight md:mt-0 md:text-6xl">
            Privacy Policy
          </h1>
        </div>
      </div>

      <div className=" bg-gradient-to-b from-[hsla(204,26%,93%,1)] to-white relative pb-36">
        <div className="absolute top-0 left-0 w-full">
          <div
            className={
              Styles.tilt +
              " after:bg-[hsla(204,26%,93%,1)] z-10 m-[-1px] after:h-[4vh] md:after:h-[10vh] z-10 "
            }
          ></div>
        </div>
        <div className="max-w-4xl p-8 xl:p-16 mx-auto bg-gradient-to-b from-[hsla(204,26%,93%,0)] to-[hsla(204,26%,99%,0)] via-white text-gray-800  relative">
          <h3 className="text-2xl font-medium">
            Welcome, and Thank you for visiting Katsana!
          </h3>
          <p className="mt-6 leading-7">
            It is important to us that you understand KATSANA Privacy Policy on
            what information we collect when you visit the Site either from a
            computer or a mobile device and what we may do with that
            information, so we have laid it all out here for you. This Privacy
            Policy also covers the collection of data from applications on
            mobile devices and Katsana GPS Tracking devices.
            <br />
            <br />
            By using this Site you consent to the terms of this Privacy Policy,
            and you signify your assent to all of the terms of this Privacy
            Policy and our Terms of Service. If you do not agree with any terms
            of this Privacy Policy or our Terms of Service, please do not use
            this Site or submit any personal information. Please take care to
            read our Privacy Policy to be sure you are informed about how your
            information may be used. Please also read our Terms of Service.
          </p>
          <h3 className="mt-6 text-2xl font-medium">Getting to Know You</h3>
          <p className="mt-6 leading-7">
            We collect and store a wide variety of information you submit to
            Katsana or give us in the other ways described below. Some is
            personal information and some isn’t.
          </p>
          <h4 className="mt-12 text-xl font-medium">
            What Is “Personally Identifiable Information” (or PII)?
          </h4>
          <p className="mt-2 leading-7">
            Information that identifies you, such as your name, email address or
            image, or that can be used to identify you when combined with other
            information, is “personally identifiable information.”
          </p>
          <h4 className="mt-6 text-xl font-medium">When do we ask for PII?</h4>
          <p className="mt-2 leading-7">
            If you choose to register to use the Site, we may collect your name,
            email address, and date of birth during the registration process.
            <br />
            <br />
            Katsana may ask you for other PII from time to time, such as contact
            information (like your mailing address and phone number), or
            demographic information (like your zip/postal code or region,
            hometown, gender, or education level). We may ask for this
            information from you in order for you to use the Site beyond just
            browsing, or for the purposes disclosed to you at the time such
            other PII is collected.
          </p>
          <h4 className="mt-6 text-xl font-medium">
            What Isn’t Personal Information?
          </h4>
          <p className="mt-2 leading-7">
            Information we can gather from your visit that doesn’t identify you
            personally, like your IP address, your operating system, your
            browser type and language, the date and time of your visit, as well
            as information about your ISP, referring URL, the search term that
            led you to the Site, your geographic region, the type of content you
            view, etc. We may do this by assigning your computer one or more
            cookies which may collect information about how you use Katsana; we
            may use standard Internet tools, such web beacons or cookies, which
            collect non-PII and track your use of our website.
          </p>
          <h4 className="mt-6 text-xl font-medium">Do I Have A Choice?</h4>
          <p className="mt-2 leading-7">
            While you’re not required to provide PII to visit the Site, many of
            our cool features may not be available to you if you choose not to
            create an account with us or, at other times, not to provide
            requested information.
          </p>
          <h3 className="mt-12 text-2xl font-medium">
            How Do We Use the Personally Identifiable Information We Collect?
          </h3>
          <p className="mt-2 leading-7">
            We use personally identifiable information collected through the
            Service and Apps for the purposes described in this Policy or
            elsewhere on the Service or in the Apps. For example, we may use
            personally identifiable information we collect:
          </p>
          <ul className="mt-4 ml-6 leading-7 list-disc">
            <li>
              to process and complete any transactions in connection with the
              Service and Apps;
            </li>
            <li>
              to request feedback and to otherwise contact you about your use of
              the Service and Apps;
            </li>
            <li>
              to respond to your emails, questions, comments, requests and
              complaints, and to provide customer service;
            </li>
            <li>to monitor and analyze Service and App usage and trends;</li>
            <li>
              to personalize and improve the Service or Apps, and our users’
              experiences with the Service and Apps (such as providing content
              or features that match interests), and to increase the Service’s
              or Apps’ functionality and user friendliness;
            </li>
            <li>
              to send you confirmations, updates, security alerts, additional
              information about our products and services and support and
              administrative messages, and otherwise facilitate your use of, and
              our administration and operation of, the Service or Apps. When
              sending marketing information about our services or products, we
              will only use your email address used during registration; we will
              not use other email addresses you may have provided to us, for
              instance in a feedback form, to send marketing emails;
            </li>
            <li>
              to notify you about important changes to the Service and Apps.
            </li>
          </ul>
          <h3 className="mt-6 text-2xl font-medium">
            What Personally Identifiable Information Do We Share With
            Third-Parties?
          </h3>
          <p className="mt-2 leading-7">
            We will not knowingly share the personally identifiable information
            we collect from you through the Service or Apps with third-parties,
            except as described in this Policy or on the Service or Apps. For
            example, we may share personally identifiable information as
            follows:
          </p>
          <ul className="mt-4 ml-6 leading-7 list-disc">
            <li>
              with vendors, consultants, and other service providers such as a
              credit card processing company to bill you for services, a forum
              provider for hosting our public forums, and an email service
              provider to send out emails on our behalf (“Service Providers”).
              When you sign up for our services we will share your personal
              information only as necessary for the third party to provide that
              service.;
            </li>
            <li>
              when you give us your consent to do so, including if we notify you
              on the Service or Apps that the information you provide will be
              shared in a particular manner and you provide such information;
            </li>
            <li>
              in an aggregated or anonymized form that does not directly
              identify you;
            </li>
            <li>
              when we believe in good faith that we are lawfully authorized or
              required to do so or that doing so is reasonably necessary or
              appropriate to comply with the law or legal processes or to
              respond to lawful requests or legal authorities including, but not
              limited to, responding to lawful subpoenas, warrants, or court
              orders;
            </li>
            <li>
              when we believe in good faith that we are lawfully authorized or
              required to do so or that doing so is reasonably necessary or
              appropriate to protect the rights, property, or safety of
              Pixelated LLP, our users, our employees, copyright owners,
              third-parties or the public including, but not limited to,
              protecting Pixelated LLP or our users from fraudulent, abusive,
              inappropriate, or unlawful use of the Service or Apps;
            </li>
            <li>
              to enforce or apply this Policy, the Terms, or our other policies
              or agreements;
            </li>
            <li>
              and in connection with, or during negotiations of, any merger,
              sale of company assets, financing or acquisition, or in any other
              situation where personally identifiable information may be
              disclosed or transferred as one of the business assets of
              Pixelated LLP.
            </li>
          </ul>
          <h3 className="mt-6 text-2xl font-medium">Security Measures</h3>
          <p className="mt-2 leading-7">
            While Katsana has security measures to help protect the information
            under our control, no data transmission over the Internet or any
            wireless network can be guaranteed to be 100% secure.
            <br />
            <br />
            Katsana tries to protect your personal information and ensure the
            security of your communications on our systems, by maintaining
            physical, organisational and technological safeguards (appropriate
            to the sensitivity of such information) designed to protect it
            against unauthorised use, disclosure or access of the personal
            information we collect on this Site. However, we cannot ensure or
            warrant that any information you transmit to us will stay safe and
            secure and you do so at your own risk.
            <br />
            <br />
            Your information may be stored in whole or in part on equipment or
            in facilities leased or licensed from others. Although Katsana tries
            to keep your information safe, it relies on the statements of those
            vendors regarding the safety and security of their storage equipment
            and services as part of its evaluation. By using the Product, you
            agree that Katsana is not responsible if the storage equipment and
            services provided by others aren’t working like they are supposed
            to, and that you will have to seek any remedies from those
            providers, not Katsana.
          </p>
          <h3 className="mt-6 text-2xl font-medium">Retention and Deletion</h3>
          <p className="mt-2 leading-7">
            We keep your personal information only so long as we need it for the
            purposes for which it was collected, for the products and services
            that you are using, or to meet any legal requirements. We have
            retention standards, which meet these requirements. We destroy your
            personal information when it is no longer needed.
          </p>
        </div>
      </div>
      <Cta />
    </Layout>
  );
};

export default PrivacyPolicy;
