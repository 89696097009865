import * as React from "react";
import LazyLoad from "react-lazyload";
import {
  MobileView,
  BrowserView,
  TabletView,
  MobileOnlyView,
} from "react-device-detect";
import FooterDesktop from "./footerdesktop";
import FooterMobile from "./footermobile";
import NavBar from "./navbar";
import NavBarMobile from "./navbarmobile";
import NavBarTablet from "./navbartablet";
import Topbar from "./topbar";
import CTADesktop from "./ctadesktop";
import WhatsApp from "./whatsapp";

const Layout = ({ pageTitle, children }) => {
  return (
    <main className="h-full min-h-screen overflow-hidden antialiased font-primary">
      <title>{pageTitle}</title>

      <Topbar />

      <MobileOnlyView>
        <LazyLoad> 
          <NavBarMobile />
        </LazyLoad>
      </MobileOnlyView>

      <TabletView>
        <LazyLoad>
          <NavBarTablet />
        </LazyLoad>
      </TabletView>

      <BrowserView>
        <LazyLoad>
          <NavBar />
        </LazyLoad>
      </BrowserView>

      
      {children}

      {/* Footer */}
      <div className=" bg-gradient-to-b from-[hsla(212,45%,15%,1)] to-[hsla(216,62%,20%,1)]">
        <div className="max-w-6xl p-8 pb-16 mx-auto xl:py-16 xl:px-0">
          <div className="h-[1px] bg-[hsla(211,48%,31%,1)] mb-10"></div>
          <MobileView>
            <LazyLoad offset={100}>
              <FooterMobile />
            </LazyLoad>
          </MobileView>
          <BrowserView>
            <LazyLoad offset={100}>
              <FooterDesktop />
            </LazyLoad>
          </BrowserView>
        </div>
      </div>

      {/* Sticky Floating CTA Button (WhatsApp & Form), Desktop Only */}
      <BrowserView>
        <CTADesktop />
      </BrowserView>

      {/* Sticky Floating CTA Button, Mobile Only */}
      <MobileView>
        <div className="fixed z-30 flex items-center justify-end w-full px-6 bottom-5">
          <span className="flex text-sm font-semibold text-white shadow-sm ">
            Message Us
          </span>
          <WhatsApp />
        </div>
      </MobileView>
    </main>
  );
};

export default Layout;
